<template>
  <Main>
    <template v-slot:content>
      <div v-if="project != null" class="project container">
        <div v-if="projects.length > 1" class="project__order-list">
          <div v-for="(item, index) in service_array" :key="index" class="project__order-list-item">
            <div :class="['project__order-item', index + 1 <= step && 'active']">
              {{ $t(`message.${item}`) }}
            </div>
            <LineDotIcon v-if="projects.length != index + 1" />
          </div>
        </div>

        <div class="project__top">
          <div class="project__top-item" v-for="item in type_of_property_array" :key="item.id">
            <label class="custom-radio__container">
              <input
                type="radio"
                name="type_of_property"
                v-model="type_of_property"
                :value="item.name"
                :disabled="project.service_type.value == 'trial'"
                @change="handleChangeProjectType"
              />
              <span class="custom-radio"></span>
              <span class="custom-radio__text">{{ item.title }}</span>
              <span class="custom-radio__desc" v-if="item.price.amount > 0">
                (+{{ item.price_formatted }})</span
              >
            </label>
          </div>
        </div>

        <template v-if="multi">
          <div v-if="project.service_type.value == 'add_furniture'" class="project__multi-text">
            {{ $t("message.Furniture_Only_upload_pictures") }}
          </div>
          <div v-if="project.service_type.value == 'remove_furniture'" class="project__multi-text">
            {{ $t("message.Clearance_Only_upload_pictures") }}
          </div>
          <div v-if="project.service_type.value == 'renovate'" class="project__multi-text">
            {{ $t("message.Only_upload_pictures_for_renovation") }}
          </div>
        </template>

        <div class="project__loader-container">
          <div class="project__loader-title">{{ $t("message.Load_your_room") }}</div>
          <div v-if="project.service_type.value == 'trial'" class="project__loader-subtext">
            {{ $t("message.You_can_upload") }} <span>1</span> {{ $t("message.photo") }}
          </div>

          <div class="project__loader-slider">
            <VueSlickCarousel v-if="isShowSlickRoom" :arrows="true" v-bind="settings">
              <div
                v-if="project.service_type.value != 'trial' || project.rooms.length < 1"
                class="project__loader-slider-item"
              >
                <div class="upload-box" @dragover.prevent @drop.prevent="handleDrop">
                  <input
                    type="file"
                    multiple
                    @change="handleFiles"
                    ref="fileInput"
                    style="display: none"
                  />
                  <div class="upload-box-content" @click="triggerFileInput">
                    <DownloadIcon />
                    {{ $t("message.Drag_your_photo_here") }}
                  </div>
                </div>
              </div>
              <div
                class="project__loader-slider-item"
                v-for="(room, index) in project.rooms"
                :key="index"
              >
                <div class="image-preview">
                  <img :src="room.image.url" :alt="'Image ' + (index + 1)" />
                  <div class="image-preview__delete" @click="removeImage(room)">
                    <DeleteIcon />
                  </div>
                </div>
              </div>
            </VueSlickCarousel>
          </div>

          <div class="project__loader-plan">
            <div class="project__loader-title pb">{{ $t("message.Upload_the_plan") }}</div>

            <div class="project__loader-plan-item">
              <div
                :class="['upload-box', project.plan_image.url && 'active']"
                @dragover.prevent
                @drop.prevent="handleDropPlan"
              >
                <input
                  type="file"
                  @change="handleFilesPlan"
                  ref="fileInputPlan"
                  style="display: none"
                />
                <div
                  :class="['upload-box-content', project.plan_image.url && 'active']"
                  @click="triggerFileInputPlan"
                >
                  <DownloadIcon />
                  {{ $t("message.Drag_your_photo_here") }}
                </div>
              </div>
              <img v-if="project.plan_image.url" :src="project.plan_image.url" alt="" />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="project-footer">
        <div class="project-footer__container container">
          <div class="project-footer__buttons">
            <div @click="handleBack" class="project-footer__button grey">
              {{ $t("message.Back") }}
            </div>
            <div @click="handleNextStep" class="project-footer__button black">
              {{ $t("message.Next_Step") }}
            </div>
            <div v-if="errorNoRoom" class="project-footer__error">
              {{ $t("message.Please_fill_in_all_mandatory") }}
            </div>
          </div>
          <div v-if="project" class="project-footer__price">
            {{ project.price_formatted }}
          </div>
        </div>
      </div>
      <FetchSpinnerModal v-if="loading" />
    </template>
  </Main>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

import baseApi from "~/api/base";
import projectApi from "~/api/project";
import ROUTE from "~/constants/routes";

import Main from "~/templates/Main.vue";
import FetchSpinnerModal from "~/components/molecules/FetchSpinnerModal.vue";

import DownloadIcon from "~/assets/images/icons/download-icon.svg";
import DeleteIcon from "~/assets/images/icons/delete-icon.svg";
import LineDotIcon from "~/assets/images/icons/line-dot.svg";

export default {
  data() {
    return {
      project: null,
      loading: false,
      isShowSlickRoom: true,
      type_of_property: "living",
      type_of_property_array: [],
      multi: false,
      step: 1,
      projects: [],
      service_array: [],
      errorNoRoom: false,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 2,
        arrows: true,
        infinite: false,
        responsive: [
          {
            breakpoint: 1350,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              arrows: true,
            },
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              arrows: true,
            },
          },
          {
            breakpoint: 568,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: true,
            },
          },
        ],
      },
    };
  },
  mounted() {
    this.getProject();
    this.getProjectTypes();

    if (this.$route.query.projects) {
      this.projects = this.$route.query.projects.split(",");
      this.multi = true;
      this.step = +this.projects.indexOf(this.$route.params.id) + 1;
    }

    if (this.$route.query.service) {
      this.service_array = this.$route.query.service.split(",");
      this.multi = true;
    }
  },
  components: {
    VueSlickCarousel,
    Main,
    DownloadIcon,
    DeleteIcon,
    FetchSpinnerModal,
    LineDotIcon,
  },
  methods: {
    getProject() {
      const url = `/${this.$route.params.id}`;
      try {
        projectApi
          .getProjectItem(url)
          .then((res) => {
            this.project = res;
            this.type_of_property = res.project_type.value;
            this.reinitializeCarousel();
          })
          .catch((error) => {
            this.$router.push({
              path: ROUTE.HOME,
            });
          })
          .finally(() => {});
      } catch (error) {
        console.log(error);
      }
    },

    handleChangeProjectType() {
      const url = `/${this.$route.params.id}`;
      const data = {
        project_type: this.type_of_property,
      };
      try {
        projectApi
          .updateProject(url, data)
          .then((res) => {
            this.getProject();
          })
          .catch((error) => {})
          .finally(() => {});
      } catch (error) {
        console.log(error);
      }
    },

    getProjectTypes() {
      try {
        baseApi
          .getTypeProjectList()
          .then((res) => {
            this.type_of_property_array = res;
          })
          .catch((error) => {})
          .finally(() => {});
      } catch (error) {
        console.log(error);
      }
    },

    handleBack() {
      if (!this.multi) {
        this.$router.push({
          path: "/",
        });
      } else {
        if (this.step == 1) {
          this.$router.push({
            path: "/",
          });
        } else {
          let url = `?projects=`;
          for (let a = 0; this.projects.length > a; a++) {
            if (this.projects.length == a + 1) {
              url += `${this.projects[a]}`;
            } else {
              url += `${this.projects[a]},`;
            }
          }

          url += "&service=";
          for (let a = 0; this.service_array.length > a; a++) {
            if (this.service_array.length == a + 1) {
              url += `${this.service_array[a]}`;
            } else {
              url += `${this.service_array[a]},`;
            }
          }

          this.$router.push({
            path: ROUTE.PROJECT_ROOMS.replace(":id", this.projects[this.step - 2]) + url,
          });
        }
      }
    },

    handleNextStep() {
      this.errorNoRoom = false;

      if (this.project.rooms.length > 0) {
        if (this.multi) {
          let url = `?projects=`;
          for (let a = 0; this.projects.length > a; a++) {
            if (this.projects.length == a + 1) {
              url += `${this.projects[a]}`;
            } else {
              url += `${this.projects[a]},`;
            }
          }

          url += "&service=";
          for (let a = 0; this.service_array.length > a; a++) {
            if (this.service_array.length == a + 1) {
              url += `${this.service_array[a]}`;
            } else {
              url += `${this.service_array[a]},`;
            }
          }
          this.$router.push({
            path: ROUTE.PROJECT_ROOMS.replace(":id", this.$route.params.id) + url,
          });
        } else {
          this.$router.push({
            path: ROUTE.PROJECT_ROOMS.replace(":id", this.$route.params.id),
          });
        }
      } else {
        this.errorNoRoom = true;
      }
    },

    triggerFileInput() {
      this.$refs.fileInput.click();
    },

    triggerFileInputPlan() {
      this.$refs.fileInputPlan.click();
    },

    async handleFiles(event) {
      this.loading = true;

      const files = event.target.files || event.dataTransfer.files;

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const createRoomUrl = `/${this.$route.params.id}/rooms`;

        if (this.project.service_type.value == "trial" && i == 1) {
          this.getProject();
          return;
        }

        try {
          // Создаем комнату
          const createRoomResponse = await projectApi.createRoom(createRoomUrl);
          const roomId = createRoomResponse.id;
          const uploadImageUrl = `/${roomId}/images`;

          // Подготавливаем данные для загрузки
          const formData = new FormData();
          formData.append("image", file);

          // Загружаем изображение
          try {
            await projectApi.uploadRoomImage(uploadImageUrl, formData);
          } catch (error) {
            const deleteRoomUrl = `/${this.$route.params.id}/rooms/${roomId}`;
            await projectApi.deleteRoom(deleteRoomUrl);
            console.error(`Error uploading file ${file.name}:`, error);
          }

          console.log(`File ${file.name} uploaded successfully.`);
        } catch (error) {
          console.error(`Error uploading file ${file.name}:`, error);
        }
      }

      await this.getProject();

      this.loading = false;
    },
    async handleFilesPlan(event) {
      this.loading = true;
      const file = event.target.files[0] || event.dataTransfer.files[0];

      const uploadPlanUrl = `/${this.$route.params.id}/plan-images`;

      const formData = new FormData();
      formData.append("image", file);

      if (this.project.plan_image) {
        await projectApi.deletePlanImage(uploadPlanUrl);
      }

      try {
        await projectApi.uploadPlanImage(uploadPlanUrl, formData);
      } catch (error) {
        console.error(`Error uploading file ${file.name}:`, error);
      }

      await this.getProject();
      this.loading = false;
    },
    async handleDrop(event) {
      this.loading = true;
      const files = event.dataTransfer.files;

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const createRoomUrl = `/${this.$route.params.id}/rooms`;

        if (this.project.service_type.value == "trial" && i == 1) {
          this.getProject();
          return;
        }

        try {
          // Создаем комнату
          const createRoomResponse = await projectApi.createRoom(createRoomUrl);
          const roomId = createRoomResponse.id;
          const uploadImageUrl = `/${roomId}/images`;

          // Подготавливаем данные для загрузки
          const formData = new FormData();
          formData.append("image", file);

          // Загружаем изображение
          try {
            await projectApi.uploadRoomImage(uploadImageUrl, formData);
          } catch (error) {
            const deleteRoomUrl = `/${this.$route.params.id}/rooms/${roomId}`;
            await projectApi.deleteRoom(deleteRoomUrl);
            console.error(`Error uploading file ${file.name}:`, error);
          }

          console.log(`File ${file.name} uploaded successfully.`);
        } catch (error) {
          console.error(`Error uploading file ${file.name}:`, error);
        }
      }

      await this.getProject();
      this.loading = false;
    },

    async handleDropPlan(event) {
      this.loading = true;
      const file = event.dataTransfer.files[0];
      const uploadPlanUrl = `/${this.$route.params.id}/plan-images`;

      const formData = new FormData();
      formData.append("image", file);

      if (this.project.plan_image) {
        await projectApi.deletePlanImage(uploadPlanUrl);
      }

      try {
        await projectApi.uploadPlanImage(uploadPlanUrl, formData);
      } catch (error) {
        console.error(`Error uploading file ${file.name}:`, error);
      }

      await this.getProject();
      this.loading = false;
    },

    async removeImage(room) {
      this.loading = true;
      const deleteImageUrl = `/${room.id}/images/${room.image.id}`;
      const deleteRoomUrl = `/${this.$route.params.id}/rooms/${room.id}`;

      try {
        await projectApi.deleteRoomImage(deleteImageUrl);
      } catch (error) {
        console.log(error);
      }

      try {
        await projectApi.deleteRoom(deleteRoomUrl);
      } catch (error) {
        console.log(error);
      }

      await this.getProject();
      this.loading = false;
    },

    reinitializeCarousel() {
      this.loading = true;
      this.isShowSlickRoom = false;
      setTimeout(() => {
        this.isShowSlickRoom = true;
        this.loading = false;
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.project {
  &__top {
    display: flex;
    padding: 25px 0;
    margin-bottom: 30px;
    border-bottom: 1px solid #d7d9dd;
  }

  &__multi-text {
    padding: 10px 30px;
    border-radius: 20px;
    background: #f9f9f9;
    color: #e2666d;
    font-family: Inter;
    font-size: 16px;
    width: fit-content;
    margin-bottom: 30px;
  }

  &__loader-title {
    font-size: 20px;
    font-family: "Mirador";
    padding-bottom: 10px;
    font-weight: 600;
  }

  &__loader-subtext {
    font-size: 16px;
    color: #8e8e8e;

    span {
      color: #e2666d;
    }
  }

  &__top-item {
    margin-right: 50px;
  }

  &__loader-slider {
    padding: 20px 0;
  }

  &__loader-slider-item {
    aspect-ratio: 4/3;
    margin-right: 10px;
    padding-right: 10px;
  }

  &__loader-plan {
    padding: 20px 0;
    border-bottom: 1px solid #d7d9dd;
    border-top: 1px solid #d7d9dd;
  }

  &__loader-plan-item {
    width: calc((100% / 4) - 10px);
    aspect-ratio: 4/3;
    position: relative;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100%);
      height: 100%;
      object-fit: cover;
    }
  }

  &__order-list {
    padding: 20px 0 20px;
    display: flex;
  }

  &__order-list-item {
    padding-right: 20px;
    display: flex;
    align-items: center;

    svg {
      margin-left: 20px;
    }
  }

  &__order-item {
    font-size: 24px;
    font-weight: 700;
    color: #191919;
    padding: 10px 30px;
    border-radius: 8px;
    background: #bec4d2;
    opacity: 0.5;

    &.active {
      opacity: 1;
    }
  }

  ::v-deep(.slick-prev),
  ::v-deep(.slick-next) {
    bottom: 0;
    opacity: 0.85;
    border-radius: 15px;
    width: 44px;
    height: 44px;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 10;
  }
  ::v-deep(.slick-prev:before),
  ::v-deep(.slick-next:before) {
    display: none;
  }
  ::v-deep(.slick-prev.slick-disabled),
  ::v-deep(.slick-next.slick-disabled) {
    opacity: 0.5;
  }
  ::v-deep(.slick-prev) {
    left: -10px;
  }
  ::v-deep(.slick-next) {
    right: -10px;
  }
  ::v-deep(.slick-prev) {
    background-image: url("../../assets/images/icons/slick-prev.png");
    background-repeat: no-repeat;
    color: transparent;
    background-size: contain;
    background-position: center;
  }
  ::v-deep(.slick-next) {
    background-image: url("../../assets/images/icons/slick-next.png");
    background-repeat: no-repeat;
    color: transparent;
    background-size: contain;
    background-position: center;
  }
}
.custom-radio__container {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
}

/* Создаем кастомный радиобатон */
.custom-radio {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  margin-right: 10px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2) inset;

  &__text {
    color: #000;
  }

  &__desc {
    color: #8e8e8e;
    padding-left: 10px;
  }
}

input[type="radio"]:disabled + .custom-radio__text {
  color: #8e8e8e;
}

input[type="radio"] {
  overflow: hidden;
  height: 0;
  width: 0;
  position: absolute;
}

/* Добавляем стиль для состояния "checked" */
input[type="radio"]:checked + .custom-radio::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #000000;
}

.upload-box {
  border: 1px solid #d7d9dd;
  background: #f9f9f9;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;

  input {
    visibility: hidden;
    position: absolute;
    width: 0;
    height: 0;
  }

  &.active:before {
    content: "";
    position: absolute;
    inset: 0;
    background: #000;
    opacity: 0.3;
    z-index: 1;
  }
}

.upload-box-content {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 2;
  font-size: 20px;
  font-weight: 700;

  &.active {
    color: #fff;

    svg {
      g {
        fill: #fff;
      }
    }
  }
}

.image-preview {
  width: 100%;
  height: 100%;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__delete {
    position: absolute;
    bottom: 0;
    right: 5px;
    cursor: pointer;
  }
}

.image-item .image-item button {
  position: absolute;
  top: 5px;
  right: 5px;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  border: none;
  padding: 5px;
  cursor: pointer;
}

.project-footer {
  padding: 30px 0;
  background: #f9f9f9;
  border-top: 1px solid #d7d9dd;

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__error {
    display: flex;
    align-items: center;
    color: #ec1b26;
    padding-right: 20px;
  }

  &__buttons {
    display: flex;
  }

  &__button {
    padding: 10px 40px;
    border-radius: 8px;
    cursor: pointer;
    margin-right: 20px;

    &.grey {
      color: #191919;
      background: #dadee7;
      transition: all ease-in-out 0.5s;

      &:hover {
        background: #b1b5bd;
        transition: all ease-in-out 0.5s;
      }
    }

    &.black {
      color: #fff;
      background: #191919;
      transition: all ease-in-out 0.5s;

      &:hover {
        background: #000;
        transition: all ease-in-out 0.5s;
      }
    }
  }

  &__price {
    font-size: 34px;
    font-weight: 600;
    color: #000;
  }
}

@media (max-width: 1350px) {
  .project {
    &__loader-plan-item {
      width: calc(100% / 3 - 10px);
    }
  }
}

@media (max-width: 991px) {
  .project {
    &__loader-plan-item {
      width: calc(100% / 2 - 10px);
    }
  }

  .project-footer {
    position: relative;

    &__error {
      position: absolute;
      bottom: 10px;
    }
  }
}

@media (max-width: 568px) {
  .project {
    &__loader-plan-item {
      width: calc(100%);
    }

    &__loader-slider-item {
      padding: 0;
      margin: 0;
    }

    &__top {
      padding: 20px 0;
      margin-bottom: 20px;
    }

    &__loader-title {
      font-size: 18px;
      padding-bottom: 0;
    }

    &__loader-slider {
      padding: 10px 0 30px;
    }

    &__loader-title {
      &.pb {
        padding-bottom: 10px;
      }
    }
  }

  .custom-radio__container {
    font-size: 14px;
  }

  .project-footer {
    &__price {
      font-size: 20px;
    }

    &__button {
      font-size: 14px;
      padding: 10px 30px;
    }
  }
}
</style>
